<template>
  <div class="wow slideInLeft" style="width:100%">
    
    <div style="height:650px;">

      <div>
          <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/contact_banner1.png" style="height:100%;width:100%"/>
      </div>

      <div style="position:absolute;top:200px;left:41%;text-align:cneter">
            <p style="color:white;font-weight:500;font-size:44px;line-height:60px;text-align:center">华财企服招募计划</p>
            <p style="line-height:30px;color:white;font-weight:400;font-size:18px;margin-top:20px;text-align:center">资源变现 实现财富自由</p>
            <p style="line-height:30px;color:white;font-weight:400;font-size:18px;margin-top:20px;text-align:center">诚邀您共同开拓万亿级蓝海市场</p>
        </div>
    </div>

    <div class="block main marginTop wow slideInLeft">
      <el-row>
        <el-col :offset="1" :span="10" style="margin-top:1%;">
          <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/contact_place.jpg">
        </el-col>
        <el-col :span="1" style="">
           &nbsp;
        </el-col>
        <el-col :span="8" style="z-index:12;position:relative;margin-top:6%">
          <el-form label-width="100px">
            <el-form-item>
              <label slot="label">姓名&emsp;&emsp;</label>
              <el-input placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item>
              <label slot="label">联系方式&emsp;&emsp;</label>
              <el-input placeholder="请输入电话号码"></el-input>
            </el-form-item>
             <el-form-item>
              <label slot="label">留言&emsp;&emsp;</label>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入留言内容">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary">提交</el-button>
            </el-form-item>

          </el-form>
        </el-col>
      </el-row>
    </div>
        <div style="background-color:white;height:450px;padding-top:10px;margin-top:20px;border-radius: 6px;">
      <p style="text-align: center;color: #2c3e50;font-weight：700;font-size:30px;margin-top:40px">联系方式</p>

      <el-row style="margin-top:50px;z-index:15">
        <el-col :span="6" :offset="2">
          <el-card  shadow="hover" style="text-align:center">
            <i class="el-icon-message" style="font-size:60px;color:orange"></i>
            <div style="padding: 14px;color:#626262;font-size:30px">
              <span style="font-size:20px;color:#1f1c2f;font-weight: 700;">电子邮箱</span><br>
              <span style="font-size:20px">huacaiqifu@www.xhkj888.cn</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6" :offset="1">
            <el-card  shadow="hover" style="text-align:center">
            <i class="el-icon-location" style="font-size:60px;color:orange"></i>
            <div style="padding: 14px;color:#626262;font-size:30px">
              <span style="font-size:20px;color:#1f1c2f;font-weight: 700;">通信地址</span><br>
              <span style="font-size:20px">北京市朝阳区悠乐汇E座1810</span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6" :offset="1">
            <el-card  shadow="hover" style="text-align:center">
            <i class="el-icon-mobile-phone" style="font-size:60px;color:orange"></i>
            <div style="padding: 14px;color:#626262;font-size:30px">
              <span style="font-size:20px;color:#1f1c2f;font-weight: 700;">联系电话</span><br>
              <span style="font-size:20px">+86 134 6665 4152</span>
            </div>
          </el-card>
        </el-col>

      </el-row>
      
    </div>
    
      
    </div>
    
    
  </div>
</template>

<script>
import axios from 'axios';
import WOW from 'wowjs';
import { tryHideFullScreenLoading, showFullScreenLoading } from '../network/serviceHelp'
  export default {
  inject: ['reload'],
  data() {
    return {
     
    }
  },
  methods:{
      

  },
  created(){
    console.log('create');
    this.trackEvent();
  },
  mounted(){
    let wow = new WOW.WOW({
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false
        });
        wow.init();
  }
  }
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
</style>